import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import {
    fitsPage,
    mainSection,
    typeFigure,
    typeFigureRight,
    fits2,
    fits2Right,
    fits3,
    fits3Right,
    fits4,
    fits4Right,
    fits5,
    fits5Right,
    fits6,
    fits6Right,
    fits7,
    fits7Right,
    fits8,
    fits8Right,
    fits9,
    fits9Right,
    fits10,
    fits10Right,
    fits11,
    fits11Right,
    fits12,
    fitsIntro,
    icon,
    fitsHeading,
    fitsAbout,
    fitsAboutText,
    fitsAboutSubtext,
    fitsImg,
    fitsQuotation,
    quoteText,
    shapeImg,
    quoteSignature,
} from "./what-fits-you.module.scss";
import Sylwetka from "../../assets/images/svg/sylwetka.svg";
import Hourglass from "../../assets/images/svg/hourglass.svg";
import Pear from "../../assets/images/svg/pear.svg";
import Apple from "../../assets/images/svg/apple.svg";
import Rectangle from "../../assets/images/svg/rectangle.svg";
import Ultra from "../../assets/images/svg/ultra.svg";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import usePageMetadata from "../../hooks/use-page-metadata";
import getTranslationKey from "../../utils/get-translation-key";

import MainLayout from "../../layouts/main-layout";
import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import Title from "../atoms/title";
import FooterStylizeSection from "../molecules/footer-stylize-section";
import WhatFitsYouSection, { ISectionRowProps } from "../organisms/what-fits-you-section";
import MainSection from "../organisms/sections/common/main-section";
import TwoBoxSection from "../organisms/sections/common/two-box-section";
import DescriptionSection from "../organisms/sections/common/description-section";
import TwoTextSection from "../organisms/sections/common/two-text-section";
import WarningIcon from "../../assets/images/svg/warning2.svg";
import AttentionIcon from "../../assets/images/svg/attention2.svg";
import Header from "../organisms/headers/header";

interface IFitsProps extends IPageBase {
    readonly data: { site: ISite } & { [key: string]: ImageDataLike };
}

type BodyShapeType = { id: number } & ISectionRowProps;

const WhatFitsYou: React.FC<IFitsProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const fitsTranslations = getTranslationKey("what-fits-you");

    const { site, coverImg } = data;
    const figureImage = getImage(coverImg);
    const { pageTitle, defaultStructuredData } = usePageMetadata(pageContext, site);

    return (
        <MainLayout
            className={fitsPage}
            headerColor={"white"}
            HeaderComponent={<Header color="white" hideLogo={true} />}
            SEOProps={{
                title: pageTitle,
                structuredData: defaultStructuredData,
            }}
        >
            <MainSection showButton={true}
                titleText={<div>Sylwetka<br />Określ figurę i dowiedz się co Ci pasuje.</div>}
                text1="Bez względu na to, czy jesteś, niska czy wysoka duża w biuście czy masz dużą pupę, dzięki moim radom w każdej stylizacji będziesz wyglądać idealnie."
                className={mainSection}
            />
            <TwoBoxSection classNameLeftWrapper={typeFigure}
                title={"Typ figury"}
                classNameRightWrapper={typeFigureRight}
                text={<div>
                    <div>Gruszki, lizaki, stożki, truskawki, kolumny, cegły, wiolonczele - wiele jest określeń na nasze kształty oraz wiele jest ich podziałów. Ale by się nie pogubić i niepotrzebnie komplikować, zacznijmy od tych podstawowych od których zaczyna się uświadomienie sobie w czym tak naprawdę będziesz dobrze wyglądać. By poznać najlepiej swoją sylwetkę, musisz stanąć przed lustrem, popatrzeć na siebie i przeanalizować atuty swojej figury, które chcesz podkreślić ubiorem, a także mankamenty, które strój pomoże nam ukryć.
                        Świadomość własnych kształtów znacząco ułatwia tworzenie ciekawych i spójnych stylizacji. Teraz weź w dłonie centymetr, aby zmierzyć po kolei: obwód ramion, tali i bioder, byś mogła dowiedzieć się jakie mają względem siebie proporcje.
                    </div>
                    <div>
                        • Ważne są nie same mierzone centymetry, a proporcje pomiędzy mierzonymi wymiarami.
                    </div>
                    <div>
                        • Liniami stylizacji nie są biust – talia – biodra, a ramiona – talia – biodra, które tworzą ramę Twojej sylwetki.
                    </div>
                </div>}
                quoteText1={"\"Rozmiar Twojego ciała jest nieważny moda zamaskuje wszystko.\""}
                quoteSubtext1={"Anna Dello Russo"} />
            <TwoBoxSection classNameLeftWrapper={fits2}
                title={"Cechy sylwetki - klepsydra"}
                classNameRightWrapper={fits2Right}
                text={<div>
                    • Linia ramion jest równa linii bioder.<br />
                    • Widoczna, często wysoka talia. Różnica między linią ramion /bioder i linią talii wynosi min. 25cm, ale na ogół jest większa i dochodzi nawet do ok. 35 cm.<br />
                    • Linia bioder jest równa linii ramion. Pamiętajmy o tolerancji do 3 cm, przy takiej różnicy linie są mniej więcej równe.<br />
                    • Uwaga! Typ sylwetki klepsydra nie ma jednej, ścisłej definicji. Kobiety<br />
                    o kształcie klepsydry mogą znacznie się od siebie różnić. Ich wspólną cechą jednak zawsze będzie proporcjonalność.<br />
                    • Aby upewnić się, że figura klepsydra to właśnie Ty, skorzystaj z bardzo prostego matematycznego obliczenia. Podziel obwód swojej talii przez odwód bioder. Wynik powinien mieścić się w przedziale od 0,71 do 0,8.<br />
                    • Ramiona są tej samej szerokości co biodra, biodra i pośladki są krągłe, a talia jest wyraźnie zarysowana.<br />
                    • Ta sylwetka jest uważana, jako ideał proporcji, więc to właśnie do niej dążymy w innych typach figur.
                </div>} />
            <TwoTextSection
                image1={AttentionIcon}
                title1="Wskazówka"
                text1="Klepsydra nie powinna zwiększać dysproporcji między poszczególnymi partiami ciała, a próbować zachować równowagę między nimi."
                image2={WarningIcon}
                title2="Uwaga"
                text2="Klepsydra posiada często lekko wystający brzuch, krótki korpus i lekko wystający brzuch i duże piersi. Jednak te jej cechy nie są regułą." />

            <TwoBoxSection classNameLeftWrapper={fits3}
                isReverse={true}
                title={"W co powinna ubierać się klepsydra?"}
                classNameRightWrapper={fits3Right}
                text={<div>
                    Fasony podkreślające talię, a więc sukienki szmizjerki, rozkloszowane,w kształcie tuby, z baskinką.<br />
                    Ołówkowe spódnice, taliowane spodnie oraz koszule. To dla ciebie będą bluzki i sukienki odcinane pod biustem czy płaszcze z paskiem np. kultowy trencz.<br />
                    Dekolt kopertowy, w kształcie litery V, a także stójki, kołnierzyki czy golfy,które wbrew powszechnemu przekonaniu mogą optycznie powiększać biust.<br />
                    Spodnie z wysokim stanem. Sprawdzą się zatem takie szorty, jeansy czy spodnie palazzo - te ostatnie dodatkowo wyszczuplają i wydłużają nogi.<br />
                    Klepsydra często boryka się z szerokimi udami. Ten mankament zamaskuje prosta nogawka.<br /><br />
                    W swoich stylizacjach seksapil buduj z klasą np. przy pomocy fasonu.<br /><br />
                    Unikaj<br /><br />
                    Wyzywających długości czy dużych wycięć.
                    Torebek „wiszących” na wysokości bioder.<br /><br />
                </div>} />
            <TwoBoxSection classNameLeftWrapper={fits4}
                isReverse={true}
                title={"Cechy sylwetki - gruszka"}
                classNameRightWrapper={fits4Right}
                text={<div>
                    • Wąska linia ramion.<br /><br />
                    • Średnio lub bardzo wcięta talia.<br /><br />
                    • Dysproporcja pomiędzy linią ramion, a strefą biodrową jest zauważalna i wynosi ok. 4-5 cm.<br /><br />
                    • Szerokie biodra, pełne uda.<br /><br />
                    • Dość duża pupa, czasami opadająca i jakby zlewająca się z udami,
                    małe piersi.<br /><br />
                    • Długa szyja.<br /><br />
                    W przypadku gruszki, mimo, że biologiczne biodra są wyżej, najszerszy punkt określający linie sylwetki jest umiejscowiony niżej. Występuje tu podwójna linia bioder, pierwsza linia znajduje się na wysokości kości biodrowych, jednak najszerszy punkt usytuowany niżej – w górnej części ud. Stefa biodrowa jest wyraźnie szersza od linii ramion. To jeden z najczęstszych typów figur.<br /><br />
                </div>} />

            <TwoTextSection
                image1={AttentionIcon}
                title1="Wskazówka"
                text1="Powinnaś wyrównać proporcje między górą, a dołem sylwetki. Staraj się wysmuklić biodra i podkreśl górną część sylwetki."
                image2={WarningIcon}
                title2="Uwaga"
                text2="Czasem u gruszek występują opadającego ramiona i tęgie nogi. Występuje różnica w rozmiarze ubrań między górną i dolną częścią sylwetki." />


            <TwoBoxSection classNameLeftWrapper={fits5}
                isReverse={true}
                title={"W co powinna ubierać się gruszka?"}
                classNameRightWrapper={fits5Right}
                text={<div>
                    WSZELKIEGO RODZAJU ŁÓDKOWE, POZIOME DEKOLTY.
                    KRÓTKIE ŻAKIETY WE WZORY.
                    DŁUGIE KARDIGANY, KTÓRE KOŃCZĄ SIĘ NAD LINIĄ
                    KOŚCI BIODROWYCH.
                    SPODNIE NOS NAJLEPIEJ DUŻO CIEMNIEJSZE NIŻ KOLORY UŻYTE W GÓRNEJ PARTII TWOJEJ SYLWETKI (NAJLEPSZA BĘDZIE DLA CIEBIE PROSTA
                    NOGAWKA).
                    SUKIENKI PROSTE LUB LEKKO ROZKLOSZOWANE O KOSZULOWEJ GÓRZE.
                    ROZKLOSZOWANE SPÓDNICE O LINII A IDEALNIE WYRÓWNAJĄ TWOJE PROPORCJE.
                    POSTAW NA TRENCZ!
                    ZIMĄ DUŻY I KOLOROWY SZAL, KTÓRY ZAWSZE NOŚ LUŹNO ZAWIĄZANY BLISKO SZYI.<br /><br />
                    KOSZULE SĄ TWOIM NAJLEPSZYM SPRZYMIERZEŃCEM, W SZCZEGÓLNOŚCI TE Z BAWEŁNY, DŽINSOWE, LNIANE (POWINNY MIEĆ LEKKO BUFIASTE RĘKAWY, KIESZENIE, PAGONY CZY ŻABOT, CZY ZDOBIENIA).<br /><br />
                    UNIKAJ!<br /><br />
                    MASYWNYCH BRANSOLETEK I DUŻYCH PIERŚCIONKÓW, PONIEWAŻ KAŻDA BIŻUTERIA NOSZONA W OKOLICACH DŁONI PODEŚLĄ BIODRA.
                </div>} />
            <TwoBoxSection classNameLeftWrapper={fits6}
                title={"Cechy sylwetki - jabłko"}
                classNameRightWrapper={fits6Right}
                text={<div>
                    GÓRNA CZĘŚĆ CIAŁA JEST ZAOKRĄGLONA NICZYM JABŁUSZKO.<br /><br />
                    • KSZTAŁT TEN OTRZYMAŁ NAZWĘ JABŁKA ZE WZGLĘDU NA WYMIARY. BARKI, PLECY I KLATKA PIERSIOWA SĄ PROPORCJONALNIE WIĘKSZE OD BIODER I UD, CO DAJE ZAOKRĄGLONY WYGLĄD.
                    <br /><br />
                    • BIUST OD ŚREDNIEGO DO BARDZO DUŻEGO.
                    <br /><br />
                    • DUŻO CIAŁA W OKOLICY BRZUCHA, PROBLEM Z „BOCZKAMI" I,OPONKĄ"
                    <br /><br />
                    • JEST NAJBARDZIEJ PROBLEMATYCZNA ZE WSZYSTKICH TYPÓW FIGUR.
                    <br /><br />
                    • BIODRA SĄ DOŚĆ WĄSKIE W PORÓWNANIU Z BARKAMI. POŚLADKI SĄ RACZEJ MAŁE, PUPA PŁASKA, A NOGI SZCZUPŁE I KSZTAŁTNE.
                    <br /><br />
                    • LEKKO WCIĘTA LUB BARDZO KRÓTKA TALIA.
                    <br /><br />
                    • LINIA BIODER JEST RÓWNA LINII RAMION LUB WĘŻSZA.
                    <br /><br />
                    • SZEROKIE I MOCNE PLECY Z TENDENCJA DO GROMADZENIA NADMIARU TKANKI TŁUSZCZOWEJ.
                    <br /><br />
                </div>} />


            <TwoTextSection
                image1={AttentionIcon}
                title1="Wskazówka"
                text1="Warto zrównoważyć proporcje sylwetki - zmniejszyć optycznie brzuch, wyeksponować nogi i wydobyć talię."
                image2={WarningIcon}
                title2="Uwaga"
                text2="Sylwetka jabłko często mają rozmiar plus size, choć oczywiście nie jest to zasadą – pamiętaj, że w rozróżnieniu typów figur liczą się proporcje ciała, a nie waga." />



            <TwoBoxSection classNameLeftWrapper={fits7}
                isReverse={true}
                title={"W co powinna ubierać się jabłko?"}
                classNameRightWrapper={fits7Right}
                text={<div>
                    UBRANIA Z MIĘKKICH MATERIAŁÓW NP. BAWEŁNA CZY WISKOZA (KOSZULE, BLUZKI).
                    DOPASOWANE OKRYCIA WIERZCHNIE BYTRZYMAŁY SYLWETKĘ"
                    DEKOLT W KSZTAŁCIE LITERY V, KTÓRY WYSMUKLI GÓRNĄ CZĘŚĆ CIAŁA.
                    SPODNIE Z REGULARNYM I NIECO WYŻSZYM STANEM:,,WCIĄGNĄ" BRZUCH I BOCZKI, WYSMUKLĄ SYLWETKĘ.
                    UBRANIA ODCIĘTE POD BIUSTEM", TAKIE ODCIĘCIE UTWORZY OSTRA, CZASEM MOŻNA SAMEMU STWORZYĆ TĄ LINIĘ PASKIEM.
                    DWUCZĘŚCIOWE DOPASOWANE SUKIENKI O DOPASOWANYM, OŁÓWKOWYM DOLE I LUŹNIEJSZEJ GÓRZE.
                    PROSTE FASONY TO COŚ DLA CIEBIE, BEZ MOCNO ZAZNACZONEJ TALII.<br /><br />
                    WYRAZISTE FORMY PŁASZCZA PODKREŚLAJĄCE SYLWETKĘ, ALE BEZ PASKA W TALII.<br /><br />
                    UNIKAJ!<br /><br />
                    ŁÓDKOWYCH DEKOLTÓW.
                    ZABUDOWANYCH POD SZYJĘ BLUZEKI KRÓTKICH „MIĘSISTYCH SWETRÓW".<br /><br />
                </div>} />
            <TwoBoxSection classNameLeftWrapper={fits8}
                title={"Cechy sylwetki - prostokąt"}
                isReverse={true}
                classNameRightWrapper={fits8Right}
                text={<div>
                    CZASEM RAMIONA PRZEKRACZAJĄ LINĘ BIODER, ŻE SYLWETKA KSZTAŁTEM PRZYPOMINA ODWRÓCONY TRÓJKĄT.
                    <br /><br />
                    • BIUST JEST NAJCZĘŚCIEJ NIEWIELKICH ROZMIARÓW.
                    <br /><br />
                    • BARDZO LEKKO ZARYSOWANA TALIA, TAK, ŽE PRAKTYCZNIE JEJ NIE WIDAĆ.
                    <br /><br />
                    • BIODRA WĘŻSZE OD RAMION, WRAŻENIE PŁASKIEGO KSZTAŁTU BIODER.
                    <br /><br />
                    • PRZEWAŻNIE WYSOKA, BARDZO SMUKŁA SYLWETKA O SZCZUPŁYCH NOGACH.
                    <br /><br />
                    CHARAKTERYZUJE SIĘ PROSTĄ POZBAWIONĄ TYPOWO KOBIECYCH KSZTAŁTÓW POSTURĄ.
                    <br /><br />
                </div>} />

            <TwoTextSection
                image1={AttentionIcon}
                title1="Wskazówka"
                text1="Podkreśl biodra, tak by wydawały się większe, by sylwetka nabrała kobiecości. Wysmuklisz górę, zmniejszysz linię ramion i wyeksponujesz nogi."
                image2={WarningIcon}
                title2="Uwaga"
                text2="Często mylona z sylwetką jabłkiem." />


            <TwoBoxSection classNameLeftWrapper={fits9}
                isReverse={true}
                title={"W co powinna ubierać się prostokąt?"}
                classNameRightWrapper={fits9Right}
                text={<div>
                    DEKOLT W KSZTAŁCIE V JEST DLA CIEBIE NAJKORZYSTNIEJSZY.
                    BLUZKI Z MIĘKKIEGO MATERIAŁU NADAJE TWOJEJ SYLWETCE LEKKOŚĆ.
                    SPÓDNICE DRAPOWANIE, DZIĘKI
                    POMARSZCZONYM
                    WARSTWOM MATERIAŁU,
                    ODROBINĘ POSZERZĄ BIODRA.
                    WYSOKO WSZYTE RAMIONA SĄ DLA CIEBIE. RĘKAWY DO ŁOKCIA, WĄSKIE, ALE NIE OBCISŁE. SPÓDNICE Z FALBANKAMI.
                    SPODNIE TYPU PUMPY, BRYCZESY, WSZYSTKIE TE, KTÓRE MAJĄ ZAOKRĄGLONĄ LINIĘ BIODER I NOGAWKĘ ZWĘŻAJĄCĄ SIĘ KU DOŁOWI, WYBIERAJ TE Z WYŻSZYM STANEM. JASNE DŽINSY. MARYNARKI DŁUŻSZE, SIĘGAJĄCE BIODER. SUKIENKI ZWIEWNE NAJLEPIEJ Z DWÓCH RODZAJÓW MATERIAŁU.
                    KRÓTKIE KURTKI NIE SĄ STWORZONE DLA CIEBIE, WYBIERZ MODEL JEDNOKOLOROWY, TALIOWANY BEZ DUŻEGO KOŁNIERZA.
                    PŁASZCZE - TALIOWANE O PROSTEJ LINII RAMION, LEKKO ROZSZERZANE KU DOŁOWI.
                    <br /><br />
                    DŁUGIE SZALE, KTÓRE NOS TAK BY TWORZYŁY LINIE PIONOWĄ.
                    <br /><br />
                    UNIKAJ!
                    <br /><br />
                    KOSZULEK ODSŁANIAJĄCYCH ŁOPATKI.<br />
                    SZEROKICH DEKOLTÓW.<br />
                    SZEROKICH KOŁNIERZY.
                    <br /><br />
                </div>} />
            <TwoBoxSection classNameLeftWrapper={fits10}
                title={"Cechy sylwetki - ultrakobieca"}
                classNameRightWrapper={fits10Right}
                text={<div>
                    KAŻDY TYP FIGURY, MA SWÓJ POWIĘKSZONY ODPOWIEDNIK.
                    <br /><br />
                    • SYLWETKI POWIĘKSZONE ZACZYNAJĄ SIĘ OD ROZMIARU 42, W ZALEŻNOŚCI OD WZROSTU I SIĘGAĆ MOGĄ AŻ DO 56.
                    <br /><br />
                    • U NISKICH KOBIET, MIERZĄCYCH PONIŻEJ 160 CM, SYLWETKĄ POWIĘKSZONĄ MOŻE BYĆ JUŻ ROZMIAR 40.
                    <br /><br />
                    • U WYSOKICH PAŃ POWIĘKSZONA TO NIEKIEDY DOPIERO 44.
                    <br /><br />
                </div>} />

            <TwoTextSection
                image1={AttentionIcon}
                title1="Wskazówka"
                text1="Pamiętaj, że najbardziej kobieco wyglądasz, jeśli twoja stylizacji uwzględnia proporcje sylwetki."
                image2={WarningIcon}
                title2="Uwaga"
                text2="Bardzo ważny jest tu odpowiednio dobrany fason, Twoje kobiece kształty mogą być Twoim atutem." />

            <TwoBoxSection classNameLeftWrapper={fits11}
                isReverse={true}
                title={"W co powinna ubierać się sylwetka ultrakobieca?"}
                classNameRightWrapper={fits11Right}
                text={<div>
                    DOPASOWANE UBRANIA, NIEOBCISŁE UBRANIA, W KTÓRYCH BĘDZIESZ MOGŁA SWOBODNIE SIĘ PORUSZAĆ I DELIKATNIE OPŁYNĄ" TWOJĄ SYLWETKĘ I PODKREŚLĄ JEJ KSZTAŁT.
                    W CAŁOŚĆ STROJU STARAJ SIĘ WKOMPONOWAĆ JAK NAJWIĘCEJ LINII PIONOWYCH. WZORY:WYBIERZ TE GRAFIKI O WIELKOŚCI PROPORCJONALNEJ DO SWOICH KSZTAŁTÓW, WYSMUKLISZ W TEN SPOSÓB CIAŁO.
                    WYRAZISTE DODATKI: WYBIERAJ DODATKI O WYRAZISTEJ FORMIE I POKAŻNYM ROZMIARZE. KONIECZNIE KUP SOBIE TRENCZ!
                    <br /><br />
                    PROSTE SUKIENKI O WYRAZISTYM WZORZE Z MIĘKKIEGO MATERIAŁU SĄ DLA CIEBIE.
                    <br /><br />
                    UNIKAJ!
                    <br /><br />
                    UBRAŃ O BARDZO LUŹNYM FASONIE NAZYWANYCH OVERSIZE. TEGO TYPU FASONY POWIĘKSZAJĄ
                    SYLWETKĘ.
                    <br /><br />
                </div>} />
            <DescriptionSection className={fits12}></DescriptionSection>

        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        coverImg: file(relativePath: { eq: "figures.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        logo: file(relativePath: { eq: "emilka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default WhatFitsYou;
